import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import FeaturesPageTemplate from './features-page-template'

const FeaturesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout footer={false}>
      <Seo title='Moolah - Features' />
      <FeaturesPageTemplate sections={frontmatter.sections} />
    </Layout>
  )
}

export default FeaturesPage

export const pageQuery = graphql`
  query FeaturesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "features-page" } }) {
      frontmatter {
        sections {
          color_left
          color_right
          text_color
          heading
          description
          display_type
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
