import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'
import FreeTrialButton from '../components/FreeTrialButton'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Section from '../components/Section'
import * as styles from './features-page-template.module.scss'

const TextCenter = ({ section }) => {
  return (
    <Row>
      <Col md={{ span: 6, offset: 3 }} className='text-center'>
        <h2>{section.heading}</h2>

        <p>{section.description}</p>

        {section.image && (
          <div className='d-flex justify-content-center align-items-center'>
            <PreviewCompatibleImage
              imageInfo={{
                image: section.image,
                alt: `Moolah`,
                style: { maxWidth: 400 },
              }}
            />
          </div>
        )}

        <div className='mb-4'>
          <FreeTrialButton fontSize='1.3rem' />
        </div>
      </Col>
    </Row>
  )
}

const TextToSide = ({ section }) => {
  return (
    <Row>
      <Col
        md={6}
        className={`order-1 ${section.display_type === 'text_right' && 'order-md-2'}`}>
        <div className='h-100 d-flex align-items-center'>
          <div className='w-100'>
            <h2>{section.heading}</h2>

            <p>{section.description}</p>

            <div className='text-center text-md-end mb-4'>
              <FreeTrialButton fontSize='1.3rem' />
            </div>
          </div>
        </div>
      </Col>

      <Col
        md={6}
        className={`order-2 ${section.display_type === 'text_right' && 'order-md-1'}`}>
        {section.image && (
          <div className='d-flex justify-content-center align-items-center'>
            <PreviewCompatibleImage
              imageInfo={{
                image: section.image,
                alt: `Moolah`,
                style: { maxWidth: 400 },
              }}
            />
          </div>
        )}
      </Col>
    </Row>
  )
}

const Page = ({ section, offset }) => (
  <>
    <ParallaxLayer offset={offset} speed={0.2}>
      <div className={styles.slopeBegin} />
    </ParallaxLayer>

    <ParallaxLayer offset={offset} speed={0.3}>
      <div className={`${styles.slopeEnd}`} />
    </ParallaxLayer>

    <ParallaxLayer offset={offset} speed={0.05}>
      <Section
        style={{
          width: '100vw',
          background: `linear-gradient(0.25turn, ${section.color_left}, ${section.color_right})`,
          color: `${section.text_color}`,
        }}>
        <Container>
          {section.display_type === 'text_center' ? (
            <TextCenter section={section} />
          ) : (
            <TextToSide section={section} />
          )}
        </Container>
      </Section>
    </ParallaxLayer>
  </>
)

const FeaturesPageTemplate = ({ sections }) => {
  const [width, setWidth] = useState()

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width } = window
      setWidth(width)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!width) return null

  return (
    <>
      <Parallax
        className={styles.container}
        pages={sections.length + (width > 768 ? 1 : 1.1)}
        style={{
          height: 'calc(100vh - var(--topbar-height))',
          background: 'var(--dark)',
        }}>
        {sections.map((section, idx) => (
          <Page key={idx} section={section} offset={idx} />
        ))}

        <ParallaxLayer offset={sections.length} speed={0.4}>
          <footer>
            <Footer />
          </footer>
        </ParallaxLayer>
      </Parallax>
    </>
  )
}

FeaturesPageTemplate.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      display_type: PropTypes.string,
      color_left: PropTypes.string,
      color_right: PropTypes.string,
      text_color: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
}

export default FeaturesPageTemplate
